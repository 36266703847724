import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Our Technologies" mdxType="SEO" />
    <h1 {...{
      "id": "our-technologies"
    }}>{`Our Technologies`}</h1>
    <p><strong parentName="p">{`The key challenge faced by solar has long been the inability to effectively store energy, in addition to reduced energy production when the sun’s rays are obscured. By combining the design of solar panels with thermal storage technology, we’ve finally overcome these limitations. Here’s a look into how our solar-thermal technology fuels our homes.`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ebbf0de099dc791584d4860d0e91fdd4/db64a/white-home.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.266666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGuvFoYUP/EABgQAQEBAQEAAAAAAAAAAAAAAAECEAMR/9oACAEBAAEFAulpRPmMi5//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAEAAwEBAAAAAAAAAAAAAAABEBEhACL/2gAIAQEABj8CAre9NxpP/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARMRBhcf/aAAgBAQABPyFMj2jkPZkqsgZBTOf/2gAMAwEAAgADAAAAEBDP/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Qh//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EKf/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhQWExUf/aAAgBAQABPxAWKCkKjdefIIZuUgDlbiUlK5AKBk6zFVtVZc//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "white-home",
            "title": "white-home",
            "src": "/static/ebbf0de099dc791584d4860d0e91fdd4/db64a/white-home.jpg",
            "srcSet": ["/static/ebbf0de099dc791584d4860d0e91fdd4/c635c/white-home.jpg 500w", "/static/ebbf0de099dc791584d4860d0e91fdd4/7e3f6/white-home.jpg 1000w", "/static/ebbf0de099dc791584d4860d0e91fdd4/db64a/white-home.jpg 1500w"],
            "sizes": "(max-width: 1500px) 100vw, 1500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Fluid Solar Thermal Panel uses fixed (non-moving) reflectors to track the sun and significantly increase the energy that can be collected by conventional thermal (flat plate and evacuated tube) collectors; thus collecting more energy from sunlight at a lower cost. When paired with any good quality evacuated tubes, the Fluid Solar Thermal Panel allows:`}</p>
    <p>{`Up to 300% more solar thermal energy to be collected, giving reliable high grade, year-round hot water Improved solar collector performance in early morning, late afternoon, and in overcast weather Surplus hot water in wintertime that can be used for hydronic space heating Surplus hot water in summertime that can be used for space cooling and food chilling`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ae1844a2ccd4c24643bcf3220244b69d/07827/gable-luxury-cutaway-heat-scaled.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.55078125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAd2b04QXKtiHAf/EABwQAAICAgMAAAAAAAAAAAAAAAECAAMEERMxMv/aAAgBAQABBQKzIMFrsyb1xCWEVhdMsPS+f//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAMAAgMAAAAAAAAAAAAAAAABERAhMTJx/9oACAEBAAY/AoiU24cHVvwswxH/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhUaEQMUH/2gAIAQEAAT8hAIQHi1FYSlptum5bYG/YFVCxCJweeNaaE//aAAwDAQACAAMAAAAQbAA8/8QAFhEAAwAAAAAAAAAAAAAAAAAAESAh/9oACAEDAQE/EDE//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhAAAwEAAQUBAAAAAAAAAAAAAREhALEQMVFhodH/2gAIAQEAAT8QKiqWbH7PYYPbjLErcpxUEQhaM2CAJHAP3IYEgAFtxycgoER0+hxhQB4cb//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gable-luxury-cutaway-heat-scaled",
            "title": "gable-luxury-cutaway-heat-scaled",
            "src": "/static/ae1844a2ccd4c24643bcf3220244b69d/7ef33/gable-luxury-cutaway-heat-scaled.jpg",
            "srcSet": ["/static/ae1844a2ccd4c24643bcf3220244b69d/c635c/gable-luxury-cutaway-heat-scaled.jpg 500w", "/static/ae1844a2ccd4c24643bcf3220244b69d/7e3f6/gable-luxury-cutaway-heat-scaled.jpg 1000w", "/static/ae1844a2ccd4c24643bcf3220244b69d/7ef33/gable-luxury-cutaway-heat-scaled.jpg 2000w", "/static/ae1844a2ccd4c24643bcf3220244b69d/07827/gable-luxury-cutaway-heat-scaled.jpg 2048w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The innovative Fluid Solar Thermal Panel is combined with Fluid Solar’s ‘Zero Energy Building’ design principles to create modern spaces that are naturally lit, heated, cooled, and ventilated with solar power; providing a healthy and productive living and working environment for occupants. The production of hot water through solar energy collection can be used to power natural ventilation and air conditioning systems as well as space heating, helping the system to remain effective all-year round. Building design is optimised to ensure that the solar collectors can function as efficiently and effectively as possible to meet occupant needs.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/57c2eb7cf0d6b08b94993fa08ef67788/5d675/12pm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "105%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEvElEQVQ4y32V6VNTZxTG+RPbGTsii2AQQoEEspBIwm7BsooiQ6FqkTqD2namH7p86Eynm6MjTtEqiOxJbsgKoZDc5C7Jr+eG0vFD2w8n731zz/u8zznnOedWGIaBYejk1QJqVqOQ08iL6QWTzMExsWiC+H6KRCxNOpnhMHUk7/WyFVQDVXytc3k1X8apME2DXE5lfeMVb5XnvA4vs7b3lOW1n3kTfc56ZJmd+Aqvtp+wGnrKRmJF1mesyf7Fm5/YiDxjU3zWN16XQSvMosHJocqy8gN3aeOO7mPp2MWtUDv3jS4eZP0sFF1MxBpYyvh4aPj4QmxRbeazUBtf6118Zfjl4pfCtEBFkSLR3RhLqZv0mnVcz/m4lXHQt+NgNN/J+LGb/kI7nZFGJtMeZlQX44adPvEZ2mthXPUyk3exFlqRNFiAJZOEkuJBZloAbfRqHQydNONWGunX3YxnPQybDryxesYzbmYKHq4LSP+hi75QK9Oyv6+5hOHv5XwKYJFENMnS0TzXsh0MGy24ju04Q/UMCcNB1SlMOxjcdjCe9DKb8zKqtxA8suOPXOJ23suS6mZ1b7lczDJgUgAfHs3SrX3IYNHOZLaNwHaThGSnf+sCvakavNvVdEbrCOzUEAzX4wzX4U/auKm1M1dq4WXsMYWsVWUJORVN8yg7Q3uxGVe+hzFhNCA3D6Ulf3Lwo5KNAcPGEDaCSjUTKTcj2U4m1E5Gs15uGG2sKL+eAloM40qCuwfTOLJ2motOegodeCUvQal2p+Sun8uMFRuYpZGxuE0K5WSo1MSosJvKu7lntPMq8ltZmxUlSig7CmPhYarWz+E8aKFNs0ke/TgOW3BEKukVdpNqPXOyTm3ZJZdyqVbH4FE7wydNfKJd4knoG2FoCsNikZR0wmJukeqTaqp3azgfOU+1FKU2VEXbzgd0y344XMmo2MDuObp3q/HIsyt2EV+8mmCiki9f3EPLGpawTdL7aT7V5qk8rsQWbcCWrqEjWUdX9BIBOTwWb2Q6Ucd0upbx7YtcS0qhBMh3VMuApGGYBn6JfY9uMbRCjmwpjESGeX/vPVqzrThp5qrRwchxB/3KZeZMDwtGM4+sTgo3M1lo5GNJiydWhVvy6xV7nPhRAI2/dRhOMBWZ4EKmlka9FV/eSbd0R8+fDgKRBiZ1h+SpiQd5DzekY0aPWxku2LiyXUVDrI5aiea71W/RTt7R4ecH83SKkH25LrpEEkGRRPehF1/YzpR6hTkR+F15F9hrw1+oLVd+/MhDV8qPM9/K0/BjyeFZp4STLGZEFGYt7lxH2TzSs57MaQ+PFNtE8PVMaB4c8Sp6pJOuaM30aU4CEkmQVlb3/5AqF04B0/G0KH1FtNVDUPfTKxMkaHYTOPFLV7QylQswlbnK7ZMbjEYCLBxM8SizwOL+PEu7szyM3OH52rLMRwHUdR3TNGWIJtjYfctWdJNNZZMtZYv10DprW2tEYhEURSEWi6OEozJ046Rl4OYyKkbOxMwWZTCoWFhlwPLUlkGrZlVRe75sloO1moaJCIFSqYSl2fIqUVktaxStczq6eUrKwqmwfizQM6YW8Ltm/a/pWtnOns/8/83+Yfh/Tu++P/0G/bf9BaJlX/KG7AqqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "12pm",
            "title": "12pm",
            "src": "/static/57c2eb7cf0d6b08b94993fa08ef67788/5d675/12pm.png",
            "srcSet": ["/static/57c2eb7cf0d6b08b94993fa08ef67788/7217d/12pm.png 500w", "/static/57c2eb7cf0d6b08b94993fa08ef67788/5d675/12pm.png 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The result is a space that remains remarkably consistent in temperature throughout the day and is able to function with significantly lower energy consumption. Shown here is a heat map taken of the Fluid Solar Thermal office building in Elizabeth Vale at 12pm, which is temperature controlled using our systems. Similar heat maps taken at 9am and 4pm on the same day were almost identical, with less than one degree variation throughout the floor (between 23 and 24 degrees Celsius).`}</p>
    <p>{`Renewable solar thermal energy collected by Fluid Solar technology ensures fixed, affordable energy costs for the occupants and year round energy over the life of any building, with low or no use of fossil fuel and minimised carbon dioxide production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      